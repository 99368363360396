<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :maxWidth="'3xl'">
    <template v-slot:content>
      <div
        class="font-small flex items-center justify-center py-3 pt-4 truncate"
      >
        <span class="truncate max-w-xs font-bold"
          >{{ job.name }}&nbsp;
        </span>
        <span class="font-bold"> </span>
        ({{ job.basicData ? job.basicData.emailsCount : job.emails_count }}
        emails)
      </div>
      <br />
      <div class="flex item-center justify-center">
        <div class="item-center justify-center px-5 py-10 p-5">
          <div class="p-3 font-medium">Download Results</div>
          <div class="p-3">
            <button
              type="button"
              class="button-submit m-0"
              @click="showDownloadFileModal(job)"
            >
              Download List
            </button>
          </div>
        </div>
        <div class="item-center justify-center px-5 py-10 p-5">
          <div class="p-3 ml-12 font-medium">Export to Integration</div>
          <div class="item-center justify-center pl-7">
            <div class="p-3">
              <button
                type="button"
                class="button-submit m-0 bg-black"
                @click="showKlaviyoExportModal(job)"
              >
                Klaviyo
              </button>
              <button class="ml-3 button-submit m-0 bg-blue-400" @click="showPopup = true;this.isOpen = false;this.exportName='getresponse'">
                GetResponse</button>
              <button class="ml-3 button-submit m-0 bg-blue-700" @click="showYotpoExportModal(job)">
                Yotpo</button>
                <button class="ml-3 button-submit m-0 bg-gray-400" @click="showIterableExportModal(job)">
                Iterable</button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button type="button" @click="closeModal" class="button-cancel">
        Cancel
      </button>
    </template>
  </DialogContainer>
  <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="popup-container">
        <div class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg">
          <fieldset>
          <legend class="sr-only">Notifications</legend>
          <div class="">
            <div class="relative flex items-start py-4">
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-bestDeliverability"
                  aria-describedby="comments-all"
                  checked="allSelected"
                  name="comments"
                  type="radio"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
                />
              </div>
              <div class="min-w-0 flex-1 text-sm px-3">
                <label for="all" class="font-bold text-gray-600"
                  >Best Deliverability</label
                ><br />
                <p
                  id="comments-all"
                  class="text-gray-400 text-sm leading-relaxed text-justify"
                >
                  <!-- Unsubscribe everything except for the
                  <span
                    class="font-bold text-black"
                    v-if="bestDeliveryCount > 1"
                    >{{ bestDeliveryCount }} emails</span
                  >
                  <span
                    class="font-bold text-black"
                    v-if="bestDeliveryCount == 1"
                    >{{ bestDeliveryCount }} email</span
                  >
                  that Alfred determined to have the highest deliverability and
                  lowest threat. -->
                  Add all low-quality contacts to a suppression list in your GetResponse account. 
                  Once you have done this, exclude the suppression list from the recipients <a href="https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html" target="_blank" class="underline cursor-pointer">(check how to do it)</a>. 
                  This way, only the contacts Alfred determined to have the highest deliverability and lowest threat will get your messages. 
                  If you have any questions, contact the GetResponse support team.
                </p>
              </div>
            </div>
            <div class="relative flex items-start py-4">
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-maximumReach"
                  aria-describedby="safetosend-description"
                  name="comments"
                  type="radio"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
                />
              </div>
              <div class="min-w-0 flex-1 text-sm px-3">
                <label for="safetosend" class="font-bold text-gray-600"
                  >Maximum Reach
                  <span class="inline-flex font-normal flex-wrap">
                    <span
                      class="text-xs px-2 text-green-800 bg-green-100 rounded-full border-gray-200"
                      >Most Popular</span
                    >
                  </span>
                </label>
                <br />
                <p
                  id="safetosend-description"
                  class="text-gray-400 text-sm leading-relaxed text-justify"
                >
                  <!-- Unsubscribe everything except for the
                  <span
                    class="font-bold text-black"
                    v-if="maximumReachCount > 1"
                    >{{ maximumReachCount }} emails</span
                  >
                  <span
                    class="font-bold text-black"
                    v-if="maximumReachCount == 1"
                    >{{ maximumReachCount }} email</span
                  >
                  that Alfred determined to be likely deliverable with
                  low-to-moderate threat levels (this may include unknowns and
                  catch-alls). Undeliverable addresses, disposable domains, and
                  high-risk contacts are excluded. -->
                  Add the undeliverable addresses, disposable domains, and high-risk contacts to a suppression list in your GetResponse account and use it to exclude contacts from the recipients <a href="https://www.getresponse.com/help/what-are-suppression-lists-and-when-to-use-them.html" target="_blank" class="underline cursor-pointer">(check how to do it)</a>. 
                  Once you exclude the suppression list from your message recipients, 
                  it will only be sent to contacts identified by Alfred as the likely deliverable with low-to-moderate threat levels. 
                  Please note this may include unknowns and catch-alls. 
                  If you have any questions, contact the GetResponse support team.
                </p>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b">
          <button
        type="button"
        class="button-submit sm:ml-3"
        @click="exportGR(job)"
      >
        Export
      </button>
      <button class="button-cancel" @click="showPopup = false">
        Cancel</button>
    </div>
        </div>
      </div>
    </transition>
    </teleport>
</template>

<script>
import DialogContainer from "@/components/common/DialogContainer";
import { reactive, ref } from "vue";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
export default {
  name: "KlaviyoDownload",

  components: {
    DialogContainer, 
  },
  setup() {
    const isOpen = ref(false);
    const showPopup = ref(false);
    const job = reactive({});
    const exportName = reactive("");
    const maximumReachCount = ref(0);
    const bestDeliveryCount = ref(0);
    const emailsCount = ref(0);
    const input_maximumReach = ref(false);
    const input_bestDeliverability = ref(false);

    return {
      job,
      isOpen,
      ExportGRList: ref(),
      uploadListModal: ref(),
      showPopup,
      input_bestDeliverability,
      input_maximumReach,
      emailsCount,
      bestDeliveryCount,
      maximumReachCount,
      exportName
    };
  },

  methods: {
    closeModal() {
      this.isOpen = false;
    },

    openModal({ job }) {
      this.job = job;
      this.isOpen = true;
      if (
        this.job.result &&
        this.job.result.verification_job &&
        this.job.result.verification_job.scores &&
        this.job.result.verification_job.scores.length > 0
      ) {
        this.bestDeliveryCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 6)
          .reduce((a, b) => a + b.email_count, 0);
        this.maximumReachCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 5)
          .reduce((a, b) => a + b.email_count, 0);
      } else {
        this.bestDeliveryCount = 0;
        this.maximumReachCount = 0;
      }
    },
    showDownloadFileModal(job) {
      this.isOpen = false;
      if (job.job_id){
        job.id = job.job_id;
      }
      eventBus.trigger("show-expert", { job });
    },
    showKlaviyoFileDownloadModal(job) {
      this.isOpen = false;
      eventBus.trigger("show-export-klaviyo", { job });
    },  
    showYotpoExportModal(job){
      this.isOpen = false;
      eventBus.trigger("show-export-yotpo", { job });
    },
    showKlaviyoExportModal(job){
      this.isOpen = false;
      eventBus.trigger("show-export-klaviyop", { job });
    },
    showIterableExportModal(job){
      this.isOpen = false;
      eventBus.trigger("show-export-iterable", { job });
    },
    async exportGR(job){
      this.job = job;
      if (
        this.job.result &&
        this.job.result.verification_job &&
        this.job.result.verification_job.scores &&
        this.job.result.verification_job.scores.length > 0
      ) {
        this.bestDeliveryCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 6)
          .reduce((a, b) => a + b.email_count, 0);
        this.maximumReachCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 5)
          .reduce((a, b) => a + b.email_count, 0);
      } else {
        this.bestDeliveryCount = 0;
        this.maximumReachCount = 0;
      }
      let best_deliverability = document.getElementById(
            "cb-bestDeliverability"
          );
      if (best_deliverability.checked) {
        if (this.job.basicData) {
          let emailNumber = this.job.basicData.emailsCount.replace(",", "");
          this.emailsCount = parseInt(emailNumber) - this.bestDeliveryCount;
        } else {
          let emailNumber = this.job.emails_count;
          this.emailsCount = parseInt(emailNumber) - this.bestDeliveryCount;
        }
        this.input_bestDeliverability = true;
      }
      let maximum_reach = document.getElementById("cb-maximumReach");
      if (maximum_reach.checked) {
        if (this.job.basicData) {
          let emailNumber = this.job.basicData.emailsCount.replace(",", "");
          this.emailsCount = parseInt(emailNumber) - this.maximumReachCount;
        } else {
          let emailNumber = this.job.emails_count;
          this.emailsCount = parseInt(emailNumber) - this.maximumReachCount;
        }
        this.input_maximumReach = true;
      }
      if (!this.input_bestDeliverability && !this.input_maximumReach) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Please select atleast one option before Exporting.",
            type: "warning",
          },
        });
        return;
      }
      const modal = useConfirmationModal();
      this.showPopup = false;
      await modal.openModal({
        title: "Are you sure you want to update this list?",
        message: `This process ${this.exportName === 'getresponse' ? 'will create a suppression list of' : 'unsubscribe'} <strong>${this.emailsCount} addresses</strong>.`,
        onSubmit: () => this.runExportingJob(),
        onClose: this.onClose,
      });
},
async runExportingJob() {
      let scores = [];

      if (this.input_bestDeliverability && this.input_maximumReach) {
        scores.push(6, 7, 8, 9, 10);
      } else if (this.input_bestDeliverability && !this.input_maximumReach) {
        scores.push(7, 8, 9, 10);
      } else if (!this.input_bestDeliverability && this.input_maximumReach) {
        scores.push(10, 9, 8, 7, 6);
      }
      if(this.exportName=="getresponse"){
        const fileDownload = await this.$alfredService.grExport({
        list_name: this.job.name,
        job_id: this.job.job_id,
        scores,
      });
      if (fileDownload) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "The list export initiated successfully!",
            type: "success",
          },
        });
        this.closeModal();
      }
      }else if(this.exportName=="yotpo"){
        const fileDownload = await this.$alfredService.yotpoExport({
        list_name: this.job.name,
        job_id: this.job.job_id,
        scores,
      });
      if (fileDownload) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "The list export initiated successfully!",
            type: "success",
          },
        });
        this.closeModal();
      }
      }

    },

    async exportKlaviyoList() {
      let input_bestDeliverability = document.getElementById(
        "cb-bestDeliverability"
      );
      let input_maximumReach = document.getElementById("cb-maximumReach");
      if (!input_bestDeliverability.checked && !input_maximumReach.checked) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Please select atleast one option before downloading.",
            type: "warning",
          },
        });
        return;
      }
    },
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>